import React from "react";

const Experience = () => {
  return (
    <div className="education-timeline bg-gray-900 text-white p-8 rounded-md custom-width">
      <h2 className="text-3xl font-bold mb-6">Experience</h2>
      <div className="relative border-l-4 border-gray-700 pl-8"></div>
      <div className="content-section custom-width">
        <div className="mb-12">
          <div className="text-xl text-left ">
            Software Engineer at Cybage Software Pvt. Ltd.
          </div>
          <div className="mb-2 text-sm text-gray-400 mb-5 text-left">
            September, 2020 – August, 2023
          </div>

          <ul className="list-disc mt-2 pl-8 text-left">
            <li>
              Spearheaded a team of four to develop a complex dynamic form in
              React within a tight 10-day deadline, ensuring seamless user
              navigation and data integrity.
            </li>
            <li>
              Developed customer-facing web applications using React, enhancing
              user experience and increasing engagement.
            </li>
            <li>
              Migrated features from Angular to React, resulting in a 30%
              performance improvement.
            </li>
            <li>
              Earned recognition from management and clients for exceptional
              leadership and coordination, consistently delivering high-quality
              results ahead of deadlines.
            </li>
            <li>
              Independently integrated a Salesforce chatbot into the project
              enhancing user interaction and support.
            </li>
            <li>
              Conducted thorough peer reviews to ensure code quality,
              extensibility, and reusability, resulting in a 15% reduction in
              post-deployment issues.
            </li>
            <li>
              Followed Agile (SCRUM) methodologies to manage and deliver
              projects on time.
            </li>
            <li>
              Ensured website accessibility by maintaining keyboard navigability
              and enhancing user experience for users with disabilities.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Experience;
